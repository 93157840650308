import axios from "../../utils/axios";

export const increaseLikeAndSave = async (likes, saveStatus,id ) => {
  // const fd = new FormData();
  //   fd.append('likes',likes);
  //   fd.append('isSaved',saveStatus);
  const dataToPatch = {
    likes: likes,
    isSaved:saveStatus
  }
  const response = await axios.patch(`/blogs/${id}`, dataToPatch);

  return response.data;
};
