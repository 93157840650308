import { increaseLikeAndSave } from "./increaseLikeAndSaveAPI";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
    blog: {},
    isLoading: false,
    isError: false,
    error: "",
};

// async thunk
export const likeAndSaveStatusUpdated = createAsyncThunk("likeAndSave/Update", async ({like,isSaved,id}) => {
    const blog = await increaseLikeAndSave(like,isSaved,id);
    console.group("inside like update ")
    console.log(isSaved)
    console.log(like)
    console.groupEnd();
    return blog;
});

const likeAndSaveSlice = createSlice({
    name: "likeAndSave",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(likeAndSaveStatusUpdated.pending, (state) => {
                state.isError = false;
                state.isLoading = true;
            })
            .addCase(likeAndSaveStatusUpdated.fulfilled, (state, action) => {
                state.isLoading = false;
                state.blog = action.payload;
            })
            .addCase(likeAndSaveStatusUpdated.rejected, (state, action) => {
                state.isLoading = false;
                state.blog = {};
                state.isError = true;
                state.error = action.error?.message;
            });
    },
});

export default likeAndSaveSlice.reducer;
