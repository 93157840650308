import React, { useEffect, useState } from "react";
import RelatedPosts from "./RelatedPosts";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { likeAndSaveStatusUpdated } from "../../features/like_save/likeAndSaveSlice";

function BlogSection({ blog }) {
  const likeAndSaveData = useSelector((state) => state.likeAndSave);
  const { blog:newBlog,isLoading, isError, error } = useSelector(
    (state) => state.blog
  );
  const [bookmarkStatus,setBookMarkStatus] = useState(blog.isSaved)
  const { blogId } = useParams();
  const [updateState, setUpdateState] = useState(false);

  const likeRef = useRef();
  const savedRef = useRef(blog.isSaved);

  const dispatch = useDispatch();
  let updateChange = () => {
    return Math.random();
  };
 

  // console.log(blog);
  const likeCountHandler = (onCLickIdentifier) => {
    if (onCLickIdentifier == "like") {
      const likeValue = parseInt(likeRef.current.innerText) + 1;
      const savedElementValue =
        savedRef.current.innerText == "Save" ? false : true;
      dispatch(
        likeAndSaveStatusUpdated({
          like: likeValue,
          isSaved: savedElementValue,
          id: blog.id,
        })
      );
      likeRef.current.innerText= likeValue;
     
    } else {
      const likeValue = likeRef.current.innerText;
      const savedElementValue =
        savedRef.current.innerText == "Save" ? true : false;
      // console.log(savedRef.current.innerText)
      // console.log(savedElementValue=="Save"?"save":"notSaved");
      dispatch(
        likeAndSaveStatusUpdated({
          like: likeValue,
          isSaved: savedElementValue,
          id: blog.id,
        })
      );
    }
  };
  return (
    <section class="post-page-container">
      {/* <!-- detailed post  --> */}
      <main class="post">
        <img
          src={blog.image}
          alt="githum"
          class="w-full rounded-md"
          id="lws-megaThumb"
        />
        <div>
          <h1 class="mt-6 text-2xl post-title" id="lws-singleTitle">
            {blog.title}
          </h1>
          <div class="tags" id="lws-singleTags">
            {/* reloads creates problem */}
            {blog.tags?.map((item) => {
              return <span>#{item}, </span>;
            })}
          </div>
          <div class="btn-group">
            {/* <!-- handle like on button click --> */}
            <button
              class="like-btn"
              id="lws-singleLinks"
              onClick={() => likeCountHandler("like")}
            >
              <i class="fa-regular fa-thumbs-up"></i>
              <span id="likeCount" ref={likeRef} value={blog.likes}>
                {blog.likes}
              </span>
            </button>
            {/* <!-- handle save on button click --> */}
            {/* <!-- use ".active" class and "Saved" text  if a post is saved, other wise "Save" --> */}
            <button
              class={`${bookmarkStatus && "active"} save-btn`}
              id="lws-singleSavedBtn"
              onClick={() => {likeCountHandler("save");
                                setBookMarkStatus(!bookmarkStatus)}}
            >
              <i class="fa-regular fa-bookmark"></i>{"  "}
              <span ref={savedRef}>{bookmarkStatus? "Saved" : "Save"}</span>
            </button>
          </div>
          <div class="mt-6">
            <p>{blog.description}</p>
          </div>
        </div>
      </main>
      {/* <!-- detailed post ends --> */}
      {/* <!-- related posts --> */}
      <aside>
        <h4 class="mb-4 text-xl font-medium" id="lws-relatedPosts">
          Related Posts
        </h4>
        <RelatedPosts tags={blog.tags} blogId={blogId} />
      </aside>
      {/* <!-- related posts ends --> */}
    </section>
  );
}

export default BlogSection;
