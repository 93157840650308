import React, { useEffect } from 'react'
import RelatedPostCard from './RelatedPostCard'
import { useDispatch, useSelector } from 'react-redux';
import { fetchRelatedBlogs } from '../../features/relatedBlogs/relatedBlogsSlice';

function RelatedPosts({tags,blogId}) {

    const {relatedBlogs,isLoading,isError, error} = useSelector(state=>state.relatedBlogs);
    const { blog } = useSelector(
      (state) => state.blog
    );
    const dispatch = useDispatch();
    // console.log(tags);
    // console.group("INside related Card")
    // console.log(blog.tags);
    // console.groupEnd()
    useEffect(() => {
       dispatch(fetchRelatedBlogs({tags:tags,id:blogId}))
      return () => {
        
      }
    }, [blogId])
    let content;
    if (isLoading) content = <div>Loading</div>;
    if (!isLoading && isError)
        content = <div className="col-span-12">{error}</div>;

    if (!isError && !isLoading && relatedBlogs?.length === 0) {
        content = <div className="col-span-12">No videos found!</div>;
    }

    if (!isError && !isLoading && relatedBlogs?.length > 0) {
        content = relatedBlogs.map((item,i)=>{
          return <RelatedPostCard key={i} blogInfo={item}/>
      })
    }
    // console.log(relatedBlogs)
  return (
    <div>
        {/* {blogId} */}
        {/* {tags} */}
       <div class="space-y-4 related-post-container">
            {/* <!-- related post  --> */}
            {/* {relatedBlogs.map((item,i)=>{
                return <RelatedPostCard key={i} blogInfo={item}/>
            })}
             */}
             {content}
            {/* <!-- related post ends --> */}
          </div>
    </div>
  )
}

export default RelatedPosts
