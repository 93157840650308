import { Link, useParams } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import Mern from "../../assets/images/mern.webp";
import Git from "../../assets/images/git.webp";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlog } from "../../features/blog/blogSlice";
import RelatedPostCard from "./RelatedPostCard";
import RelatedPosts from "./RelatedPosts";
import { likeAndSaveStatusUpdated } from "../../features/like_save/likeAndSaveSlice";
import BlogSection from "./BlogSection";

function Blog() {
  const { blog, isLoading, isError, error } = useSelector(
    (state) => state.blog
  );
  // const likeAndSaveData = useSelector((state) => state.likeAndSave);
  const { blogId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBlog(blogId));

    return () => {};
  }, [dispatch, blogId]);
  // console.log(blog);
  let content = "";
  if (isLoading) {
    content = <div>Loading</div>;
  }
  if (!isLoading && !isError) {
    content = <BlogSection blog={blog} />;
  }
  return (
    <div>
      <div class="container mt-8">
        <Link
          to="/"
          class="inline-block text-gray-600 home-btn"
          id="lws-goHome"
        >
          <i class="mr-2 fa-solid fa-house"></i>Go Home
        </Link>
      </div>
      {content}
    </div>
  );
}

export default Blog;
